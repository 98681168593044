import NavBar from "./NavBar";
import "../styles/Location.css"

const Location = () => {
    return (
        <div>
            <NavBar />
            <section id="location" className="py-5">
                <div className="container">
                    <div className="row text-center">
                    <div className="col">
                        <h2 className="section-title">Location</h2>
                        <p className="lead">44 WEST 125TH STREET BETWEEN LENOX & 5TH AVE</p>
                        <p className="lead">Room:2R ( ASK FOR KONIBA!)</p>
                    </div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13684.186898244356!2d-73.9440239!3d40.806906!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f60cf07637d1%3A0xae658003b2cbd81!2sBest%20Braiders!5e1!3m2!1sen!2sus!4v1727053023225!5m2!1sen!2sus" width="600" height="450" style={{border: "0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="location"></iframe>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Location;