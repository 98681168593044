import "../styles/NavBar.css";
import { useNavigate } from "react-router-dom";

const NavBar = ({style}) => {
    const navigate = useNavigate()

    const handleNaviagation = (path) => {
        navigate(path)
    }

    return (
        <nav className="navbar" style={style}>
            <ul className="nav-links">
                <li><p onClick={() => handleNaviagation("/")}>Home</p></li>
                <li><p onClick={() => handleNaviagation("/about")}>About</p></li>
                <li><p onClick={() => handleNaviagation("/services")}>Services</p></li>
                <li><p onClick={() => handleNaviagation("/gallery")}>Gallery</p></li>
                <li><p onClick={() => handleNaviagation("/location")}>Location</p></li>
                <li><p onClick={() => handleNaviagation("/contact")}>Contact</p></li>
            </ul>
        </nav>
    )
}

export default NavBar;
