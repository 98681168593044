const services = [{name: "Box Braids", price: "$150"}, 
    {name: "Knotless Braids", price: "$180"}, 
    {name: "Faux Locks", price: "$150"}, 
    {name: "Sengalese Twist", price: "$160"}, 
    {name: "Kinky Twist", price: "$120"}, 
    {name: "Micro Braids", price: "$200"}, 
    {name: "Lemon Braids", price: "$120"}, 
    {name: "Kids' Box Braids", price: "$120"}, 
    {name: "Corn Rows / Fulani", price: "$140"}, 
    {name: "Weaves", price: "$120"}, 
    {name: "Dread Locks", price: "$80"}, 
    {name: "Men Freestyle", price: "$50"}, 
    {name: "Flat Twist", price: "$70"}, 
    {name: "Crochet Braids", price: "$150"}]

export default services;