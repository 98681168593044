import NavBar from "./NavBar"
import "../styles/Gallery.css"
import images from "./galleryImages"

const Gallery = () => {
    return (
        <div>
            <NavBar />
            <section id="gallery" className="py-5">
              <div className="container">
                <div className="row text-center">
                  <div className="col">
                    <h2 className="section-title">Gallery</h2>
                    <p className="lead">Take a look at some of our stunning hair braiding creations.</p>
                  </div>
                </div>
    
              <div className="row mt-4">
                {Object.keys(images).map((imageName, index) => {
                  return <div className="col-md-4 mb-4" key={index}>
                            <div className="gallery-item">
                                <img src={images[imageName]} alt={imageName} className="img-fluid rounded" />
                            </div>
                    </div>
                  })}
                </div>
              </div>
            </section>

            <section id="testimonials" className="py-5 text-center">
              <div className="container">
                <h2 className="section-title">What Our Clients Say</h2>
                <p className="lead">Here's what our clients have to say about their experience.</p>

                <div className="row mt-4">
                  <div className="col-md-4">
                    <div className="testimonial">
                      <p className="testimonial-text">"Amazing service! I love my new braids, and the process was so smooth. Highly recommend!"</p>
                      <h5 className="client-name">- Jessica R.</h5>
                    </div>
                  </div>

                
                  <div className="col-md-4">
                    <div className="testimonial">
                      <p className="testimonial-text">"The best braiding experience I've ever had. I feel so beautiful and confident. Thank you!"</p>
                      <h5 className="client-name">- Maria K.</h5>
                    </div>
                  </div>

                  
                  <div className="col-md-4">
                    <div className="testimonial">
                      <p className="testimonial-text">"Fantastic work! My hair looks amazing, and I can't stop getting compliments on it."</p>
                      <h5 className="client-name">- Ashley B.</h5>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        </div>
    )
}

export default Gallery;