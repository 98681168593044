import "../styles/Home.css"
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate()

    const switchToServicePage = () => {
        navigate("/services")
    }

    return (
        <div className="home-container">
        <NavBar />
            <header className="hero-section">
                <div className="container text-center text-white">
                    <h1 className="hero-title">Welcome to Koniba's African Hair Braiding</h1>
                    <p className="hero-subtitle">Where Tradition Meets Style</p>
                    <button className="hero-btn" onClick={switchToServicePage}>View Our Services</button>
                </div>
            </header>
        </div>
    )
}

export default Home;