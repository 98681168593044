import NavBar from "./NavBar";
import "../styles/Services.css"
import services from "./listOfServices";
import { useNavigate } from "react-router-dom";
const Services = () => {
    const navigate = useNavigate()
    const handleContactPage = () => {
        navigate("/contact")
    }
    return (
        <div>
            <NavBar />
            <section id="services" className="py-5">
                <div className="container">
                    <div className="row text-center">
                        <div className="col">
                            <h2 className="section-title">Our Services</h2>
                            <p className="lead">Explore the range of beautiful hair braiding styles we offer.</p>
                            <p className="lead">If a hairstyle you want isn't listed, reach out to us on the <span style={{color: "#5bc0de", cursor: "pointer"}} onClick={handleContactPage}>contact page</span> to see if we can offer you the hairstyle!</p>
                        </div>
                    </div>
    
                    <div className="row mt-4">
                        {services.map((item, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className="service-card">
                                    {/* <img src="./images/service-images/Long-Bomb-Twists.jpg" alt="Long Bomb Twists" className="img-fluid rounded"> */}
                                    <h4 className="mt-3">{item.name}</h4>
                                    <p>{item.price} & Up</p>
                                </div>
                            </div>
                        ))}
                    </div>
    
                    <div style={{textAlign: "center"}}>
                        <p className="lead">Please pay before Service! <span style={{color: "#5bc0de"}}>(Bring Cash)</span></p>
                        <p className="lead">No refunds!</p>
                        <p className="lead">No repair after <span style={{color: "#5bc0de"}}>3 days!</span></p>
                        <p className="lead">No smoking/alcohol/pets!</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;