import NavBar from "./NavBar";
import "../styles/Contact.css"
import emailjs from "@emailjs/browser"
import React, {useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
const Contact = () => {
    const form = useRef();
    const navigate = useNavigate()

    useEffect(() => {
        emailjs.init({
            publicKey: process.env.REACT_APP_PUBLIC_KEY,
            // Do not allow headless browsers
            blockHeadless: true,
            limitRate: {
              // Set the limit rate for the application
              id: "Koniba's African Hair Braiding",
              // Allow 1 request per 10s
              throttle: 10000,
          },
        })
    })

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm(process.env.REACT_APP_EMAIL_JS_SERVICE_NAME, process.env.REACT_APP_EMAIL_JS_TEMPLATE_NAME, form.current, {
            publicKey: process.env.REACT_APP_PUBLIC_KEY
        }).then(() => {
            alert("contact form submitted!")
            console.log('SUCCESS!');
            navigate("/")
        }, (error) => {
            console.log('FAILED...', error);
            alert("contact currently is unavailable, please contact us at hpoloproductions@gmail.com or at 646-316-5436")
        });
    }
    return (
        <div>
            <NavBar />
            <section id="contact" className="py-5">
                <div className="container">
                    <h2 className="section-title text-center">Get in Touch</h2>
                    <p className="lead text-center">Send us a message! Submit the type of hairstyle you want, a number to reach out to, and we'll contact you as soon as possible!</p>

                    <div className="row justify-content-center">
                    <div className="col-md-8">
                        <form id="contact-form" ref={form}>
                        <div className="form-group">
                            <label htmlFor="name">Your Name</label>
                            <input type="text" className="form-control" id="name" name="client_name" placeholder="Enter your name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Your Number</label>
                            <input type="email" className="form-control" id="email" name="number" placeholder="Enter your number" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="subject">Type of hairstyle</label>
                            <input type="text" className="form-control" id="subject" name="hairstyle" placeholder="Enter hairstyle" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Your Message</label>
                            <textarea className="form-control" id="message" rows="5" name="message" placeholder="Enter any other details you want to add (ex. ideal time + day of appointment)" required />
                        </div>
                        <div className="text-center mt-4">
                            <button type="submit" className="btn btn-primary" onClick={sendEmail}>Send Message</button>
                        </div>
                        </form>
                        
                    </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact;