function importAllImages(r) {
    let images = {};
    r.keys().forEach((item, index) => { 
        images[item.replace('./', '')] = r(item); 
    });
    return images;
}
  
const images = importAllImages(require.context('..//images/gallary-images', false, /\.(png|jpe?g|svg|JPE?G)$/));

export default images;