import "../styles/About.css"
import AboutMeImage from "../images/Koniba-pfp.jpg"
import NavBar from "./NavBar"
import { useNavigate } from "react-router-dom"
const About = () => {
    const navigate = useNavigate()

    const navigateToServices = () => {
        navigate("/services")
    }
    return (
        <div>
            <NavBar style={{backgroundColor: "#555"}} />
            <section id="about" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="section-title">About Us</h2>
                            <p className="lead">
                            Welcome to Koniba's Hair Braiding, where we specialize in a variety of stunning braiding styles including Senegalese Twist, Flat Twist, Kinky Twist, Corn Rows, Goddess Braids, Waves, and much more. Our goal is to provide exceptional hair braiding services that are tailored to your unique needs and preferences.
                            </p>
                            <p>
                            Our talented and experienced team of braiders is dedicated to creating beautiful and long-lasting styles that will leave you feeling confident and beautiful. We use only the highest quality products and techniques to ensure that your hair stays healthy and vibrant, no matter what style you choose.
                            </p>
                            <p>
                            So why wait? Treat yourself to a new look today and visit Koniba's Hair Braiding. You can trust us to deliver the perfect style that will make you stand out from the crowd. Come by and ask for Koniba – we look forward to seeing you!
                            </p>
                            <button onClick={navigateToServices} className="btn btn-primary mt-3">View Our Services</button>
                        </div>
                        <div className="col-md-6">
                            <img src={AboutMeImage} alt="About" className="img-fluid rounded shadow" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
    )
}

export default About;